<template>
    <div>
        <div v-for="(item, index) in list" :key="index">
            <div class="item1">
                <div class="header">巡检记录：{{ $moment(item.CREATED_DT).format('YYYY-MM-DD') }}</div>
                <div class="title">
                    <div class="name">
                        <span>[{{ item.Safe_Class_Name }}]</span>
                        {{ item.CONF_NAME }}
                    </div>
                    <div :class="item.RET_TXT == '正常' ? 'success' : item.RET_TXT == '异常' ? 'err' : ''">
                        {{ item.RET_TXT }}
                    </div>
                </div>

                <div class="info">
                    <div class="tit">巡检位置：</div>
                    <div>{{ item.Loca }}</div>
                </div>
                <div class="info">
                    <div class="tit">巡检人员：</div>
                    <div>{{ item.SUB_NAME }}</div>
                </div>
                <div class="info">
                    <div class="tit">巡检时间：</div>
                    <div>{{ $moment(item.CREATED_DT).format('YYYY-MM-DD') }}</div>
                </div>
            </div>

            <div class="item2">
                <div class="title">巡检内容:</div>
                <div>{{ item.DESCRIPTION }}</div>
                <div>{{ item.NORMAL_MESSAGE }}</div>
            </div>

            <div class="item3" v-if="item.PIC_URL">
                <div class="title">相关照片:</div>
                <div>
                    <img class="img" :src="item.PIC_URL" alt="" @click="img.show=true">

                    <van-popup v-model="img.show" ><img :src="item.PIC_URL" alt="" width="100%"></van-popup>
                </div>

            </div>
        </div>

    </div>
</template>
<script>
export default {
    data() {
        return {
            list: [],
            img:{show:false}
        }
    },
    mounted() {
        this.getInfo()
    },
    methods: {
        getInfo() {
            let self = this;
            this.whale.remote.getCollection({
                url: '/api/Mobile/SAFE/RecApi/GetList',
                data: { ID: self.$route.query.id },
                completed(m) {
                    self.list = m.ITEMS
                }
            })
        }
    },
}
</script>
<style lang="less" scoped>
.item1 {
    background: #fff;
    font-size: 15px;
    padding-bottom: 15px;

    .header {
        background: #58aaf2;
        color: #fff;
        padding: 6px 10px;
    }

    .title {
        display: flex;
        width: 80%;
        margin: 10px auto;
        justify-content: space-between;
        align-items: center;


        .name {
            font-weight: bold;
            font-size: 16px;

            span {
                color: #1492ff;
                font-weight: normal;
                font-size: 14px;
            }
        }

        .success {
            color: #26b400;
            background: #e8ffe2;
            padding: 5px 10px;
        }

        .err {
            color: red;
            background: #ffe7e2;
            padding: 5px 10px;
        }
    }

    .info {
        display: flex;
        width: 80%;
        margin: 10px auto;
        align-items: center;

        .tit {
            color: #b8bac2;
            margin-right: 15px;
        }
    }
}

.item2,
.item3 {
    background: #fff;
    margin: 20px auto;
    padding: 15px 10px 10px;
    font-size: 15px;
    color: #30374e;

    .title {
        margin-bottom: 10px;
        color: #333333;
    }

    >div:nth-child(2) {
        margin-bottom: 10px;
        margin-left: 10px;
    }

    >div:nth-child(3) {
        margin-left: 10px;
    }
}

.item2 {}

.item3 {
    .img {
        width: 100px;
        height: 100px;
    }
}
</style>